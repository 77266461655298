.background-projects-img-projects {
  background: url("../../../logo/Projects.svg") no-repeat center;
  background-size: contain;
  height: 40vh;

  > h1 {
    font-size: 5.5rem;
  }
}

.text-color-projects {
  color: #0f5763;  //#1d3145;
  white-space: pre-line;
}

@media (max-width: 980px) {

  .size-title-projects {
    font-size: smaller;
  }

  .size-words-mobile-projects {
    font-size: 2rem;
  }

}

@media (min-width: 979px) {

  .size-words-projects {
    font-size: 2.5rem;
  }
}

.custom-size-project {
  font-size: 20px;
  margin-top: 0.7rem;
  white-space: pre-line;
}
