.background-standard-img-company-standards {
  background: url("../../../logo/Standard.svg") no-repeat center;
  background-size: contain;
  height: 30vh;
  > h1 {
    font-size: 5.5rem;
  }
}

h1 {
  font-size: 5.5rem;
}

.text-color-company-standards {
  color: #0f5763;  //#1d3145;
  white-space: pre-line;
}

@media (max-width: 980px) {

  .size-title-company-standards {
    font-size: smaller;
  }

  .size-words-mobile-company-standards {
    font-size: 2rem;
  }

}

@media (min-width: 979px) {

  .size-words-company-standards {
    font-size: 2.5rem;
  }
}

.custom-size-standards {
  font-size: 20px;
  margin-top: 0.7rem;
  white-space: pre-line;
}
