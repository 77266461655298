.background-about-img-about {
  background: url("../../../logo/Engineering.svg") no-repeat center;
  background-size: contain;
  height: 30vh;
  > h1 {
    font-size: 5.5rem;
  }
}

.h1 {
  font-size: 2.5rem;
}

.text-color-about {
  color: #0f5763;  //#1d3145;
  white-space: pre-line;
}

.year-about {
  font-size: 2.5rem;
  font-family: "Michroma", serif;
}

@media (max-width: 980px) {

  .size-title-about {
    font-size: smaller;
  }

  .size-words-mobile-about {
    font-size: 2rem;
  }

}

@media (min-width: 979px) {

  .size-words-about {
    font-size: 2.5rem;
  }

}

.custom-size-about {
  font-size: 20px;
  margin-top: 0.7rem;
  white-space: pre-line;
}
