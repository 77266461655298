.color-nav {
  background-color: #dde7e8;
}

.navbar-light .navbar-nav .nav-link {
  color: #0897b1; //#4371A0
  font-size: 1.5rem;
}

.navbar-nav {
  align-items: center;
}

.logo-image {
  margin: 0 0 0 10%;
}

.active{
  color: #0f5763;  //#1d3145;
}

.navbar-toggler {
  margin-right: 2%;
}

.button-lang {
  font-size: 1rem;
}
