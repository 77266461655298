.background-career-img-career {
  background: url("../../../logo/Career.svg") no-repeat center;
  background-size: contain;
  height: 35vh;
  > h1 {
    font-size: 5.5rem;
  }
}

@media (max-width: 980px) {

  .size-title-career {
    font-size: 3.5rem;
  }

  .size-words-mobile-career {
    font-size: 2rem;
  }

}

@media (min-width: 979px) {

  .size-words-career {
    font-size: 2.5rem;
  }
}

.custom-size-career {
  font-size: 20px;
  margin-top: 0.7rem;
  white-space: pre-line;
}

