.background-img-contacts {
  background: url("../../../logo/Contacts.svg") no-repeat center;
  background-size: contain;
  height: 30vh;
  > h1 {
    font-size: 5.5rem;
  }
}

.text-color-contacts {
  color: #0f5763;  //#1d3145;
}

.h_iframe iframe {
  width:100%;
  height:100%;
}

.custom-height-row-contacts {
  height: 42vh;
}

@media (max-width: 980px) {
  .border-top-line {
    border-top: 3px solid #0897b1 !important;
  }

  .size-title-contacts {
    font-size: smaller;
  }

  .custom-contacts {
    margin-top: 0.7rem;
  }
}

@media (min-width: 979px) {

  .border-left-line {
    border-left: 3px solid #0897b1 !important
  }
}

.custom-img-contacts {
  padding: 4px;
  border: 0.5px solid #0897b1;
  margin-top: 1rem;
}


